import { NewReleases } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "src/components/generic/Tag/Tag";
import { TAG } from "src/components/generic/Tag/types";
import { TimedComponent } from "src/components/generic/TimedComponent/TimedComponent";

import { IconButtonWithTooltip } from "../../components/generic/IconButtonWithTooltip";
import { ProductUpdateMenuButton } from "./components/ProductUpdateMenuButton";
import { ProductUpdateMenuLink } from "./components/ProductUpdateMenuLink";
import { ProductUpdateModal } from "./components/ProductUpdateModal";
import { DEFAULT_NEW_TAG_EXPIRY_TIME, PRODUCT_UPDATE_PAGE_URL } from "./constants";
import { PRODUCT_UPDATES, type ProductUpdate } from "./productUpdates/productUpdates";
import { useProductUpdateModal } from "./useProductUpdateModal";

/**
 * Icon button, with dropdown menu of product updates
 */
export function ProductUpdateMenu({ trigger }: { trigger?: React.ReactNode }) {
    const { t } = useTranslation("modules", { keyPrefix: "productUpdate.menu" });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const mostRecentProductUpdate = PRODUCT_UPDATES.find((update) => update.openOnStartup) ?? PRODUCT_UPDATES[0];
    const newTagExpiryDate = new Date(mostRecentProductUpdate.date.getTime() + DEFAULT_NEW_TAG_EXPIRY_TIME);
    const { isOpen, currentProductUpdate, openProductUpdateModal, closeProductUpdateModal } = useProductUpdateModal({
        defaultProductUpdate: mostRecentProductUpdate,
    });

    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {trigger ? (
                <MenuItem
                    component={Button}
                    key={`ProductUpdateMenu`}
                    sx={{
                        textTransform: "none",
                        width: "100%",
                    }}
                    onClick={handleOpen}
                >
                    {trigger}
                </MenuItem>
            ) : (
                <IconButtonWithTooltip
                    badgeContent={
                        <TimedComponent expiry={newTagExpiryDate} showExpiredForAdmin={true}>
                            <Tag tagVariant={TAG.NEW} sx={{ fontSize: "8px" }} wrapperSx={{ height: "14px" }} />
                        </TimedComponent>
                    }
                    badgeSx={{ top: 2, right: -12 }}
                    onClick={handleOpen}
                    icon={<NewReleases />}
                    title={t("title")}
                />
            )}
            <Menu anchorEl={anchorEl} keepMounted style={{ zIndex: 1400 }} open={open} onClose={handleClose}>
                {PRODUCT_UPDATES.map((update: ProductUpdate) => (
                    <ProductUpdateMenuButton
                        key={`ProductUpdateMenu_${update.title}`}
                        title={update.title}
                        date={update.date}
                        tagExpiryDate={newTagExpiryDate}
                        tagVariant={update.tagVariant}
                        onClick={() => {
                            openProductUpdateModal(update);
                            handleClose();
                        }}
                    />
                ))}
                <Box sx={{ my: 2, borderTop: "1px solid #e0e0e0" }} />
                <ProductUpdateMenuLink title={t("allUpdates")} url={PRODUCT_UPDATE_PAGE_URL} onClick={handleClose} />
            </Menu>
            <ProductUpdateModal onClose={closeProductUpdateModal} date={currentProductUpdate?.date} isOpen={isOpen}>
                {currentProductUpdate?.component}
            </ProductUpdateModal>
        </>
    );
}
